import axios from '@/libs/ajax'
import { Message } from 'element-ui'
import menuList from '@/router/menus' // 所有菜单

export default {
  /**
   * 获取用户权限，处理菜单数据
   * @description 之所以把菜单数据的处理逻辑放到这里，是因为登陆成功后要先找出第一个有权限的菜单路径跳转
   * @author zhouxiaohei
   */
  getPermission({ commit }) {
    function fmtMenu(menuList, ids) {
      return menuList.filter(item => {
        // 无 id 的不用权限，注意快递打单-工作台权限码用的：0
        const isAuth = ((!item.id && item.id !== 0) || ids.indexOf(item.id) > -1) && !item.hidden
        return isAuth
      }).map(m => {
        m = Object.assign({}, m)
        if (m.children && m.children.length) {
          m.children = fmtMenu(m.children, ids)
        }
        return m
      })
    }

    // 查找第一个有权限的菜单路径
    function findFirstPath(menu) {
      let path = ''
      if (menu) {
        if (menu.children && menu.children.length) {
          path = findFirstPath(menu.children[0])
        } else {
          path = menu.path
        }
      }
      return path
    }

    return new Promise((resolve) => {
      axios({
        method: 'get',
        url: 'web/user/perm/codes',
        transformRes: true,
      }).then(res => {
        if (!res) {
          Message.error('出错啦')
          return
        }
        if (+res.code === 200) {
          const ids = res.data || []
          const menus = fmtMenu(menuList, ids)
          const firstMenuPath = findFirstPath(menus[0])
          const menuInfo = {
            menus, // 有权限的菜单数据
            firstMenuPath, // 第一个有权限的菜单路径
          }
          commit('setMenuInfo', menuInfo)
          commit('setPermission', ids)
          resolve(ids)
        } else {
          Message.error(res.msg || '出错啦')
        }
      }).catch(err => console.log(err))
    })
  }
}
