<template>
  <el-row class="header">
    <el-col :span="16" class="menu">
      <el-menu mode="horizontal" :default-active="$route.path" router>
        <el-menu-item v-for="item in menus" :key="item.path" :index="item.path">{{ item.label }}</el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="8" class="user-info">
      <el-dropdown>
        <div class="el-dropdown-link">
          <el-avatar icon="el-icon-user-solid" :size="24" />
          <span class="name">{{ profile }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="$router.push('/export-records')">导出记录</el-dropdown-item>
          <el-dropdown-item @click.native="$router.push('/password')">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="loginout()">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
  </el-row>
</template>

<script>
  import { mapState } from 'vuex'
  import cookies from 'vue-cookies'

  export default {
    props: {
      profile: {
        type: String,
        default: ""
      },
    },

    computed: {
      ...mapState('app', ['menuInfo']),
      menus() {
        const menuInfo = this.menuInfo || {}
        return menuInfo.menus || []
      },
    },

    methods: {
      loginout() {
        this.$axios.get(
          `logout`,
          {
            withCredentials: true
          }
        ).then(() => {
          cookies.remove('token')
          cookies.remove('SID')
          this.$router.push('/login')
        }).catch((error) => {
          console.error(error.response)
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
  //清理浮动
  .cf {
    zoom: 1;

    &:before,
    &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }
  }
  .header {
    position: relative;
    overflow:hidden;
  }

  .contact-info {
    [class*="el-icon-"] {
      font-size: 20px;
      margin-right: 10px;
    }

    span+span {
      margin-left: 6%;
    }
  }

  .user-info {
    text-align: right;
    font-size: 14px;
  }

  // 下拉菜单
  .el-dropdown {
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      background: $silver;
    }
    .el-dropdown-link {
      display: flex;
      align-items: center;
      height: 60px;
      .name {
        margin-left: .6em;
      }
    }
  }
</style>
