export const isProd = process.env.NODE_ENV === 'production'
export const isOnline = location.hostname === '120.78.131.177'

export let apiBaseURL = '/wkapi/'
export let env = 'pro' // 环境：pro-生产；dev-开发；test-测试
export const siteName = ''

// 禁止登录的账号
export const forbiddenAccounts = [
  '开渠达国际',
  '佳速达',
  '火火国际',
  '好朋友',
  '优泰国际',
  '浙江益捷',
  '集浩国际',
  '鑫诚创联',
  '乘风破浪',
  'DXJJnevo',
  '跨世通',
  '宏邦物流',
  'HQT',
  '宏捷国际',
  '歌运国际',
  '美升国际',
  'HYDS',
  'YYS-邮易斯',
]

switch (location.hostname.split('.')[0]) {
  case '192':
  case '127':
  case 'localhost':
  case 'dev': // 开发环境
    env = 'dev'
    break
  case 'test': // 测试环境
    env = 'test'
    break
}

export default {
  isProd,
  isOnline,
  apiBaseURL,
  env,
  siteName,
  forbiddenAccounts,
}
