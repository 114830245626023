export default [
  // {
  //   path: '/home',
  //   name: '工作台',
  //   component: () => import('@/views/workbench/index'),
  // },
  {
    path: '/account',
    name: '余额',
    component: () => import('@/views/workbench/account'),
  },

  // 订单管理
  {
    path: '/order/place-order',
    name: '创建订单',
    component: () => import('@/views/order/place-order'),
  },
  {
    path: '/order/place-order-batch',
    name: '批量下单',
    component: () => import('@/views/order/place-order-batch'),
  },
  {
    path: '/order/order-manage',
    name: '订单列表',
    component: () => import('@/views/order/order-manage/order-manage'),
  },
  {
    path: '/order/wrong-order',
    name: '问题订单',
    component: () => import('@/views/order/wrong-order'),
  },

  // 交易管理
  {
    path: '/finance/bank-slip',
    name: '交易流水',
    component: () => import('@/views/finance/bank-slip'),
  },

  // 系统设置
  {
    path: '/password',
    name: '修改密码',
    component: () => import('@/views/login/password'),
  },
  {
    path: '/export-records',
    name: '导出记录',
    component: () => import('@/views/system/export-records'),
  },
  {
    path: '/dev-info',
    name: '开发者信息',
    component: () => import('@/views/system/dev-info'),
  }
]
