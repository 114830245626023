<template>
  <svg class="iconsvg" :class="iconName" aria-hidden>
    <use :href="`#${iconName}`" />
  </svg>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      }
    },
    computed: {
      iconName () {
        return `icon-${this.name}`
      }
    }
  }
</script>

<style scoped>
  .iconsvg {
    width: 1em;
    height: 1em;
    line-height: 1;
    overflow: hidden;
    vertical-align: -.15em;
    fill: currentColor;
  }
</style>
