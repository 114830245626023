import dayjs from 'dayjs'
import { fixDate } from '@/libs/wjtools/util'

export const filters = {
  /**
   * 功能：将时间戳按照给定的时间/日期格式进行处理
   * @param {Number} date 时间戳
   * @param {String} fmt 时间格式 'YYYY-MM-DD HH:mm:ss'
   * @returns {String} 规范后的 时间/日期 字符串
   */
  // fmtDate: (time, fmt) => formatDate(time, fmt),
  fmtDate(time, fmt = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) return time
    time = fixDate(time) // 格式化时间为苹果系统可兼容的格式
    return dayjs(time).format(fmt)
  },

  // 数字千分位分割
  addThousands(num) {
    if (!num || num + '' === '0' ) return 0
    num = String(num)
    if (num.includes('.')) {
      return num.replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => $1 + ',')
    } else {
      return num.replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,')
    }
  },
}

export default Vue => {
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
}
