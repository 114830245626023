import stat from './index'
import { notChanged, isEmpty } from './utils'

export default function (el, binding) {
  if (notChanged(binding) || isEmpty(binding)) return

  if (el.removeEventListeners && typeof el.removeEventListeners === 'function') {
    el.removeEventListeners()
  }

  // use modifier as events
  const events = Object.keys(binding.modifiers).filter(modifier => binding.modifiers[modifier])
  if (!events.length) events.push('click') // listen click event by default

  // addEventListener for each event, call trackEvent api
  const listeners = []
  events.forEach((event, index) => {
    listeners[index] = () => stat.report(binding.value, event)
    el.addEventListener(event, listeners[index], false)
  })

  // a function to remove all previous event listeners in update cycle to prevent duplication
  el.removeEventListeners = () => {
    events.forEach((event, index) => {
      el.removeEventListener(event, listeners[index])
    })
  }
}
