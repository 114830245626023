<template>
  <el-dialog
    :title="title"
    :visible="value"
    :width="width"
    :fullscreen="isFullscreen"
    :top="top"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :lock-scroll="lockScroll"
    :class="['v-dialog', {'v-dialog-scroll': maxHeight}, {'v-dialog-center': isCenter}]"
    :custom-class="customClass"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :show-close="false"
    :before-close="beforeClose"
    :destroy-on-close="destroyOnClose"
    @open="handleChange('open')"
    @opened="handleChange('opened')"
    @close="handleChange('close')"
    @closed="handleChange('closed')"
  >
    <slot name="header">
      <div slot="title" class="v-header">
        <p>{{ title }}</p>
        <div class="actions">
          <i v-if="showFullscreen" class="el-icon-full-screen" @click="isFullscreen = !isFullscreen" />
          <i v-if="showClose" class="el-icon-close" @click="handleChange('close')" />
        </div>
      </div>
    </slot>

    <div class="v-body">
      <!-- 多套一层为了设置内容区max-height让滚动条到最右边，实际这样体验更佳 -->
      <div class="v-body-inner" :style="{'max-height': maxHeight}" ref="vBodyScroll" @[eventName]="handleEvent">
        <div v-if="tipType || tipText" class="v-dialog-tip">
          <span v-if="tipType === 'info'" class="v-dialog-tip-circle"><iconsvg name="i" /></span>
          <span v-else-if="tipType === 'success'" class="v-dialog-tip-circle"><iconsvg name="tick-bold" /></span>
          <h3 v-if="tipText" class="v-dialog-tip-info">{{ tipText }}</h3>
        </div>
        <slot />
      </div>
    </div>

    <slot name="footer">
      <div slot="footer" v-if="!footerHide" class="v-footer" :style="{width: footerWidth}">
        <el-button v-if="showCancelBtn" class="v-btn" :class="['v-btn-cancel-' + cancelBtnStyle]" @click="cancel">{{ cancelText }}</el-button>
        <el-button v-if="showOkBtn" class="v-btn v-btn-ok" type="primary" :loading="loading" @click="ok">{{ okText }}</el-button>
      </div>
    </slot>
  </el-dialog>
</template>

<script>
  let vBodyScroll = null
  let timestamp = null

  export default {
    props: {
      value: Boolean,
      title: {
        type: String,
        default: '提示'
      },
      width: {
        type: String,
        default: '50%'
      },
      fullscreen: { // 是否全屏显示
        type: Boolean,
        default: false
      },
      top: { // 是否全屏显示
        type: String,
        default: '15vh'
      },
      modal: { // 是否显示遮罩层
        type: Boolean,
        default: true
      },
      modalAppendToBody: { // 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上
        type: Boolean,
        default: true
      },
      appendToBody: { // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
        type: Boolean,
        default: false
      },
      lockScroll: { // 是否在 Dialog 出现时将 body 滚动锁定
        type: Boolean,
        default: true
      },
      customClass: { // Dialog 的自定义类名
        type: String,
        default: ''
      },
      closeOnClickModal: { // 是否可以通过点击 modal 关闭 Dialog
        type: Boolean,
        default: true
      },
      closeOnPressEscape: { // 是否可以通过按下 ESC 关闭 Dialog
        type: Boolean,
        default: true
      },
      showFullscreen: { // 是否显示右上角全屏按钮
        type: Boolean,
        default: false
      },
      showClose: { // 是否显示右上角关闭按钮
        type: Boolean,
        default: true
      },
      beforeClose: { // 是否可以通过按下 ESC 关闭 Dialog
        type: Function,
        default: () => {}
      },
      destroyOnClose: { // 关闭时销毁 Dialog 中的元素
        type: Boolean,
        default: false
      },
      showOkBtn: { // 是否显示确定按钮（可用来做'我知道了'、'好的'之类的提示框，注意footerHide要改为false）
        type: Boolean,
        default: true
      },
      okText: { // 确定按钮文字
        type: String,
        default: '确定'
      },
      showCancelBtn: { // 是否显示取消按钮
        type: Boolean,
        default: true
      },
      cancelText: { // 取消按钮文字
        type: String,
        default: '取消'
      },
      cancelBtnStyle: { // 取消按钮样式：1-蓝色边框和文字 2-蓝色边框和文字加浅蓝色背景 3-灰色边框和黑色文字
        type: String,
        default: '1'
      },
      cancelClose: { // 点击默认的取消按钮是否关闭弹窗，兼容某些场景点击取消按钮不关闭弹窗，为 false 时注意 cancel 和 close 事件要分开监听
        type: Boolean,
        default: true
      },
      footerHide: { // 实际场景自定义居多，默认隐藏
        type: Boolean,
        default: false
      },
      footerWidth: { // 底部宽度，用到底部默认按钮不需要跟整体宽度一样时可以设置
        type: String,
        default: '100%'
      },
      maxHeight: { // 内容区可滚动 (支持px和vh)
        type: String,
        default: ''
      },
      isCenter: { // 弹窗是否要垂直居中（!!!居中了外面组件注意要自己设置max-height，否则弹窗超过屏幕高度时会没法看到顶部超出的部分）
        type: Boolean,
        default: true
      },
      loading: { // 确定按钮的loading动效
        type: Boolean,
        default: false
      },
      eventName: { // 传字符串 scroll, 会监听内容区的 scroll 事件, 页面上的 Select\DatePicker 之类的下拉框加了 transfer, 滚动时不会自动收起导致错位, 直接用 iview 的 events-enabled 会有性能卡顿, 而且超出滚动区也不会隐藏
        type: String,
        default: ''
      },
      tipType: { // 提示类型：info、success、warning、error，一般用来做确认操作或成功提示
        type: String,
        default: ''
      },
      tipText: { // 提示文字内容，可和 tipType 配合使用
        type: String,
        default: ''
      }
    },

    data() {
      return {
        isFullscreen: this.fullscreen,
      }
    },

    watch: {
      value(v) {
        if (v && this.maxHeight) { // 设置了最大高度，重置滚动距离
          if (this.eventName === 'scroll') timestamp = null
          this.$nextTick(() => {
            vBodyScroll = this.$refs.vBodyScroll
            vBodyScroll.scrollTop = 0
          })
        }
      }
    },

    methods: {
      handleEvent() {
        if (!this.eventName) return
        if (this.eventName === 'scroll') {
          // 简单节流一下
          let curTimestamp = new Date().valueOf()
          if (!timestamp || (curTimestamp - timestamp > 1000)) {
            vBodyScroll.click()
            timestamp = curTimestamp
          }
        } else {
          this.$emit(this.eventName)
        }
      },

      handleChange(e) {
        if (e === 'close') {
          this.$emit('input', false) // 方便父组件 v-model 时直接关闭弹窗，不用再额外监听事件
          this.$emit('close')
          return
        }
        this.$emit(e)
      },

      ok() {
        this.$emit('ok')
      },

      // 将按钮的取消和弹窗关闭区分开
      cancel() {
        this.$emit('cancel')
        if (this.cancelClose) this.handleChange('close')
      }
    },
  }
</script>
<style lang='scss' scoped>
.v-dialog {
  .v-header {
    padding: 0 0 8px;
    border-bottom: 1px solid #F0F0F0;
    font-size: 16px;
    font-weight: bold;
    color: $black;
    position: relative;
    .actions {
      position: absolute;
      top: 0;
      right: 0;
      color: #C1C1C1;
      .el-icon-full-screen {
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: $black;
          transform: scale(1.1);
          transition: .3s;
          will-change: transform;
        }
      }
      .el-icon-close {
        margin-left: 3px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          color: $black;
          transform: rotate(180deg);
          transition: .3s;
          will-change: transform;
        }
      }
    }
  }
  .v-footer {
    display: flex;
    padding: 10px 20px 0;
    margin: 0 auto;
    .v-btn {
      flex: 1;
      // border-radius: 4px;
      height: 40px;
      font-size: 14px;
      color: $main-color;
      border: 1px solid $main-color;
      background: #FFF;
      &:not(:first-child) {
        margin-left: 20px;
      }
      &.v-btn-ok {
        border: none;
        background: $main-color;
        color: #FFF;
        font-weight: bold;
      }
      &.v-btn-cancel-2 {
        background: #ECF2FF;
      }
      &.v-btn-cancel-3 {
        border-color: $lgray;
        color: $black;
      }
    }
  }
  .v-body {
    padding: 20px 20px 0;
    .v-body-inner {
      .v-dialog-tip {
        text-align: center;
        .v-dialog-tip-circle {
          width: 64px;
          height: 64px;
          // border-radius: 50%;
          background: #ECF2FF;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .iconsvg {
            font-size: 24px;
            color: $main-color;
            line-height: 64px;
          }
        }
        .v-dialog-tip-info {
          font-size: 16px;
          text-align: center;
          color: $black;
          margin: 12px 0 22px;
        }
      }
    }
  }
  // 坑：.el-dialog 有默认的 box-sizing: border-box，src\assets\scss\resetlayout.scss 重置样式里设置了所有元素 box-sizing: inherit;
  // 会导致 overflow-y: auto 失效，始终会存在滚动条
  &.v-dialog-scroll { // 内容区设置了最大高度，可滚动
    .v-body {
      padding: 0 0 0 20px;
      .v-body-inner {
        // width: 100%; // 100% 会导致 box-sizing: content-box 后滚动条跑到弹窗外面去了
        box-sizing: content-box;
        padding-top: 20px;
        padding-right: 20px;
        overflow-y: auto;
        overflow-y: overlay; // 滚动条不会挤压内容宽度，部分浏览器不兼容
        scrollbar-width: thin; // 火狐浏览器
        scrollbar-color: #FAFAFA; // 火狐浏览器
        &::-webkit-scrollbar {
          height: 16px;
          width: 16px;
          background: #FAFAFA;
        }
        &::-webkit-scrollbar-thumb {
          background: #C7C7C7;
          background-clip: content-box;
          // border-radius: 8px;
          border: 4px solid transparent;
        }
      }
    }
  }
  &.v-dialog-center { // 垂直居中
    display: flex;
    ::v-deep .el-dialog {
      margin: auto !important;
    }
  }
  ::v-deep .el-dialog {
    // border-radius: 8px;
    overflow: hidden;
  }
  ::v-deep .el-dialog__header {
    padding: 20px 20px 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
