import axios from 'axios'
import config from '@/config'
import { Loading } from 'element-ui'
import cookies from 'vue-cookies'

let loadingInstance
axios.defaults.baseURL = config.apiBaseURL

// 请求拦截
axios.interceptors.request.use(
  config => {
    config.withCredentials = true
    config.timeout = 600000
    loadingInstance =
      config.showLoading &&
      Loading.service({
        text: 'Loading.....',
        lock: false,
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.8)',
        spinner: 'el-icon-loading'
      })

    let token = cookies.get('token')
    if (
      token &&
      token != null &&
      typeof token != 'undefined' &&
      token != 'undefined'
    ) {
      config.headers.token = token
    }
    config.headers = {
      'Content-Type': 'application/json',
      ...config.headers,
    }
    return config
  },
  error => {
    loadingInstance && loadingInstance.close()
    if (error.response && error.response.status == 401) {
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  function(response) {
    // 2xx 范围内的状态码都会触发该函数
    loadingInstance && loadingInstance.close()
    // $axios 发送请求时设置了 transformRes 为 true(非axios 官方的 transformResponse 配置)，直接返回 data 方便前端使用
    if (response.status === 200 && response.config.transformRes) {
      return response.data || {}
    }
    return response
  },
  function(error) {
    // 超出 2xx 范围的状态码都会触发该函数
    loadingInstance && loadingInstance.close()

    let errMsg
    const response = error.response
    if (response) {
      if (response.status == 401) { // 重新登陆
        errMsg = '登录信息已失效，请重新登录'
        window.location.href = '/login'
      } else {
        const data = response.data || {}
        const dataMsg = data.message || data.msg
        const statusText = response.statusText
        errMsg = dataMsg || statusText || '出错了'
      }
    } else if (error.message) {
      errMsg = error.message
    }

    // 有错误信息时自己模拟一份错误数据返回，解决后端动不动就直接 http 状态码 500、401...
    // 直接 reject 的话外层需要自己 catch，还需要额外判断 await 的返回值
    if (errMsg) {
      return {
        message: errMsg,
        msg: errMsg,
        success: false,
        data: { // 兼容未解构获取数据的逻辑 transformRes
          message: errMsg,
          msg: errMsg,
          success: false,
        }
      }
    }
    return Promise.reject(error)
  }
)

export default axios
