/**
 * 去除输入框首尾空格
 * ❶ element-ui 的输入框el-input加了 trim 会导致字符中间不能输入空格(文档上有说：不支持 v-model 修饰符)
 * ❷ 需同时过滤掉输入内容里的零宽字符，一般是用户直接从 excel 中直接复制粘贴进来的内容
 * ‎‏‍‌‪‫‭‮‬
 * 123456‎7890‎‏12
 */

const TRIM = {
  inserted: el => {
    // 兼容第三方输入组件，如el-input
    const inputTag = el.tagName !== 'INPUT' ? el.querySelector('input') : el
    const handler = function(event) {
      const oldVal = event.target.value || ''
      // 过滤掉零宽字符和首尾空格
      const newVal = oldVal.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '').trim()
      if (oldVal != newVal) {
        event.target.value = newVal
        dispatchEvent(inputTag, 'input')
      }
    }
    el.inputTag = inputTag
    el._blurHandler = handler
    inputTag.addEventListener('blur', handler)
  },

  unbind: el => {
    const { inputTag } = el
    inputTag.removeEventListener('blur', el._blurHandler)
  }
}

function dispatchEvent(el, type) {
  const evt = document.createEvent('HTMLEvents')
  evt.initEvent(type, true, true)
  el.dispatchEvent(evt)
}

export default TRIM
