
// require.context是webpack提供的api用来创建上下文作用域
// 三个参数分别为：目录、是否搜索子文件夹、匹配文件名的正则
const fileInfo = require.context('./', false, /^\.\/(?!index.js).*\.js$/)
const fileNameList = fileInfo.keys() || []

export default {
  // 插件为对象时，Vue.use()会默认调用install
  install: function(Vue) {
    fileNameList.forEach(fileName => {
      const directive = fileInfo(fileName)
      // 提取路径中的文件名作为指令名称
      const directiveName = fileName.replace(/^(\.\/)([a-zA-Z0-9-_]+)\.js$/, '$2')
      // 注册指令
      Vue.directive(directiveName, directive.default || directive)
    })
  }
}
