import wj from '@/libs/wjtools'
import mutations from './mutations'
import actions from './actions'

const state = {
  menuInfo: wj.store('menuInfo') || {},
  permission: wj.store('permission') || [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
