import wj from '../wjtools'
import directive from './directive'

export default {
  install(Vue, options) {
    if (this.installed) return

    // 设置插件配置
    this.options = options

    // 缓存浏览器标识
    if (!wj.session('browser')) {
      const browser = new wj.Browser()
      wj.session('browser', browser)
    }

    // 缓存来源渠道标识
    const channelid = wj.getParam('channelId')
    if (channelid) {
      wj.session('channelid', channelid)
    }

    // 缓存客户端标识
    if (!wj.cookie('clientid')) {
      const clientid = wj.util.uuid()
      wj.cookie('clientid', clientid, { expires: 365 })
    }

    // add $report to Vue prototype
    Object.defineProperty(Vue.prototype, '$istat', {
      get: () => this,
    })

    Vue.directive('report', directive)
    this.installed = true
  },

  // 上报统计数据
  report(params, eventname = 'click') {
    if (this.options.debug || !params) return // 调试模式

    // 可以只传上报名称
    if (typeof params === 'string') {
      params = { action: params }
    }

    // 通过 Image 对象请求统计链接
    const url = this.options.url || 'https://log.kjhaoyun.com/wk.gif'
    const browser = wj.session('browser')
    const userInfo = wj.store('userInfo') || {}
    const { name, action, actionid, data = null } = params
    const img = new Image(1, 1)
    img.src = wj.setParams(url, {
      name: name || `${document.title}_${action}`, // 上报名称
      action, // 上报行为
      actionid: actionid || action,
      source: '1', // 1-客户端；2-管理端；3-供应商端；4-客户端小程序；
      event: eventname, // 触发事件
      userid: userInfo.userId, // 用户Id
      username: userInfo.username, // 用户名
      clientid: wj.cookie('clientid'), // 客户端标识
      channelid: wj.session('channelid'), // 外链渠道标识
      url: document.URL,
      domain: document.domain,
      referrer: document.referrer,
      page_title: document.title,
      page_path: location.pathname,
      data: data && JSON.stringify(data), // 附加数据
      browser: browser && JSON.stringify(browser), // 客户端信息
      // target: '', // 备用参数——触发目标
    })
  },
}
