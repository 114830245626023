<template>
  <div class="v-pager">
    <el-pagination
      :current-page.sync="page.index"
      :page-size="page.size"
      :total="page.total"
      :layout="layout"
      v-bind="$attrs"
      v-on="$listeners"
      @current-change="changePage"
      @size-change="changePageSize"
    />
  </div>
</template>

<script>
  /**
   * 分页器公用组件
   * 在 el-pagination 基础上二次封装
   * 页面不用自己再单独去监听 current-change 和 size-change 事件
   */
  export default {
    name: 'VPager',
    props: {
      page: {
        type: Object,
        default: () => ({
          index: 1,
          size: 10,
          total: 0
        })
      },
      layout: { // 组件布局：sizes, prev, pager, next, jumper, ->, total, slot
                // 其中的 -> 看源码是箭头后面的模块都给包在类名为 el-pagination__rightwrapper 的标签下了，加个样式 float: right，没看明白要搞啥
        type: String,
        default: 'total, sizes, prev, pager, next'
      },
      // 以下这3个参数不让 $attrs 往下透传，无任何用处，实际传递的是 page 里的信息
      currentPage: { // 当前页数
        type: Number,
        default: 1
      },
      pageSize: { // 	每页显示条目个数
        type: Number,
        default: 10
      },
      total: { // 总条目数
        type: Number,
        default: 0
      }
    },
    data() {
      return {}
    },
    methods: {
      changePage(pageIndex, pageSize = this.page.size) {
        this.page.index = pageIndex
        this.$emit('update:page', this.page)
        this.$emit('change', pageIndex, pageSize)
      },
      changePageSize(pageSize) {
        this.page.size = pageSize
        this.$emit('update:page', this.page)

        // 页码为1时 changePage 不会触发 (element-ui 和 iview 都有这个问题)
        if (this.page.index === 1) {
          this.changePage(1)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.v-pager {
  text-align: right;
  margin-top: 10px;
}
</style>
