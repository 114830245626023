<template>
  <el-container class="g-layout">
    <!-- <el-aside class="g-layout-aside" width="208px" hidden>
      <div>
        <el-menu
          router
          unique-opened
          background-color="#011528"
          text-color="#B5B7BD"
          :default-active="$route.path"
        >
          <VSubmenu v-for="(item, index) in menus" :key="index" :item="item" />
        </el-menu>
      </div>
    </el-aside> -->

    <el-container>
      <el-header class="g-layout-header" height="60px">
        <VHeader :profile="profile" :balance="balance" :admin="admin" :channel-info="channelInfo" />
      </el-header>
      <el-main class="g-layout-main">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import wj from '@/libs/wjtools'
  import VHeader from './v-header'
  // import VSubmenu from './v-submenu'

  export default {
    components: {
      VHeader,
      // VSubmenu,
    },

    data() {
      return {
        channelInfo: [],
        vipLogo: null,
        profile: '',
        balance: 0,
        admin: false,
      }
    },

    computed: {
      ...mapState('app', ['menuInfo']),
      menus() {
        const menuInfo = this.menuInfo || {}
        return menuInfo.menus || []
      },
    },

    created() {
      this.getPermission()
      this.getUserInfo()
    },

    methods: {
      ...mapActions('app', ['getPermission']),
      async getUserInfo() {
        const res = await this.$axios({
          method: 'get',
          url: 'user-info',
          transformRes: true
        }).catch(err => console.log(err))

        const data = res.data || {}
        const { username, admin, needAlarm, balance, channelInfo } = data
        if (res.success && username) {
          wj.store('userInfo', data)
          if (data.userId === '4ba1912a-6b0d-4947-b974-308d795fdf5b') {
            this.vipLogo = 'https://kjdms.oss-cn-shenzhen.aliyuncs.com/images/JLSS_Logo.png'
          }

          if (admin == false && needAlarm){
            this.$alert('当前可用余额不足500美元，为保证正常下单，请联系平台客服进行充值。', {
              type:'warning',
              showClose: false,
              callback: () => {
                this.readAlarm()
              }
            })
          }

          this.profile = username
          this.admin = admin
          this.balance = balance
          this.channelInfo = channelInfo || []
          if (!data.menus) {
            this.$router.push('/nopermission')
          }
        } else if (!res.success) {
          this.$router.push('/login')
        } else {
          this.$message.error(res.message || '获取用户名和菜单失败！')
        }
      },

      // 已读余额预警
      readAlarm() {
        this.$axios({
          method: 'get',
          url: 'alarm/msg/update'
        }).catch(err => console.log(err))
      },
    }
  }
</script>

<style lang="scss" scoped>
  .g-layout {
    height: 100vh;
    background-color: $color-bg;
    .g-layout-aside {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 0 8px;
      background: $color-aside;
      @include scrollbar();
      .g-layout-logo {
        margin: 14px 16px;
      }
      .icon-logo {
        width: 118px;
        height: 35px;
        color: #FFF;
        margin: 14px 16px;
      }
      ::v-deep .el-menu {
        border: none;
        .el-icon-arrow-down:before {
          content: $triangle-arrow-down;
        }
        .el-menu-item.is-active {
          background: $main-color !important;
          border-radius: 4px;
          font-weight: bold;
          color: #FFF;
        }
        .iconsvg {
          font-size: 20px;
          vertical-align: middle;
        }
      }
      .copyright {
        font-size: 12px;
        color: #eaecf5;
        line-height: 18px;
        width: 100%;
        text-align: center;
        margin: 30px 0;
      }
    }
    .g-layout-header {
      position: relative;
      z-index: 9;
      border: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
      background: #fff;
    }
    .g-layout-main {
      padding: 0;
      @include scrollbar();
    }
  }
</style>
