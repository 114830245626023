/**
 * 侧边栏菜单
 * 权限由接口下发 id 给前端来控制
 * 路由定义依然在 router/index.js 里, 只重构了侧边栏菜单, 路由因为涉及历史的一些跳转, 并未去改动路由表
 * icon 用的 iconfont 上的 wk-pc: https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=3818554
 */
export const notShowApiInfoHosts = ['qian-lima.com','www.qian-lima.com', 'yida-123.com','www.yida-123.com'].includes(location.hostname)
export default [
  {
    id: 101,
    label: '创建订单',
    icon: 'menu-place-order',
    path: '/order/place-order',
  },
  {
    id: 105,
    label: '批量下单',
    icon: 'menu-place-orders',
    path: '/order/place-order-batch',
  },
  {
    id: 102,
    label: '订单列表',
    path: '/order/order-manage',
  },
  {
    id: 103,
    label: '问题订单',
    path: '/order/wrong-order',
  },
  {
    id: 1,
    label: '余额',
    path: '/account',
  },
  {
    id: 408,
    label: '交易流水',
    path: '/finance/bank-slip',
  },
  {
    id: -1,
    label: '开发者信息',
    path: '/dev-info',
    hidden: notShowApiInfoHosts
  }
]
